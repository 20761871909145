import { Guid } from 'guid-typescript'
import _ from 'lodash'

export interface IPageModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class PageModel implements IPageModel {
  private _name: ValueOrFactory<string>
  private _icon: ValueOrFactory<string>
  private _active: ValueOrFactory<boolean>
  private _order: ValueOrFactory<number>
  private _displayName: ValueOrFactory<string>

  get id(): string {
    return Guid.create().toString()
  }

  get name(): string {
    return this.getValue(this._name)
  }

  get icon(): string {
    return this.getValue(this._icon)
  }

  get active(): boolean {
    return this.getValue(this._active)
  }

  get order(): number {
    return this.getValue(this._order)
  }

  get displayName(): string {
    return this.getValue(this._displayName)
  }

  constructor(init?: PartialValueOrFactory<PageModel>) {
    ;(Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T>(value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
