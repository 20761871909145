export class ApiUrl {
  /**
   * ApiUrl() gives the endpoint for the web api
   */
  public static webapiurl() {
    switch (window.location.host) {
      case 'insights4duediligence.wecr.wur.nl':
        return 'https://insights4DueDiligence.wecr.wur.nl/WebApi'
      case 'acc.insights4duediligence.wecr.wur.nl':
        return 'https://acc.insights4DueDiligence.wecr.wur.nl/WebApi'
      case 'tst.insights4duediligence.wecr.wur.nl':
        return 'https://tst.insights4DueDiligence.wecr.wur.nl/WebApi'
      default:
        return 'http://localhost:50256'
    }
  }
}